//helpfull functions

export const removeAndAddListeners = (elem, listenerName, func) => {
  elem.removeEventListener(listenerName, e => func(e))
  elem.addEventListener(listenerName, e => func(e))
}

export const fetchAPI = (url, headers) => {
  return fetch(url, {
    headers,
  }).then(r => (r.ok ? r.json() : r))
}

export const fetchPostAPI = (params = { url, formData, method: 'POST', headers }) => {
  return fetch(params.url, {
    method: params.method ? params.method : 'POST',
    headers: params.headers ? params.headers : {},
    body: params.formData,
  }).then(r => r.json())
}

export const fetchGetAPI = (params = { url, headers, formData }) => {
  let newUrl = new URL(params.url.substring(0, 4) != 'http' ? `${window.location.origin}${params.url}` : `${params.url}`)

  for (const entry of params.formData.entries()) {
    newUrl.searchParams.append(entry[0], entry[1])
  }

  return fetch(newUrl, {
    method: 'GET',
    headers: params.headers ? params.headers : {},
  }).then(r => r.json())
}

export const fetchPostJsonAPI = (params = { url, json, method: 'POST', headers }) => {
  return fetch(params.url, {
    method: params.method,
    headers: {
      'Content-type': 'application/json',
      ...params.headers,
    },
    body: JSON.stringify(params.json),
  }).then(r => r.json())
}

export const map = (value, x1, y1, x2, y2) => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

export const diffTimeMinutes = (start, end) => (new Date('1970-1-1 ' + end) - new Date('1970-1-1 ' + start)) / 1000 / 60

export const addTimeDiff = (start, end) => {
  const diff = diffTimeMinutes(start, end)

  const hours = diff / 60
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)

  return {
    hours: rhours,
    minutes: rminutes,
  }
}

export const addMultipleTimeDiffs = (times, currentUser) => {
  let totalMinutes = 0
  times.forEach(time => {
    if (currentUser == 0 || currentUser == time.currentUser) {
      totalMinutes += diffTimeMinutes(time.start, time.end)
    } else if (currentUser == 0) {
      totalMinutes += diffTimeMinutes(time.start, time.end)
    }
  })

  const hours = totalMinutes / 60
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)

  return {
    hours: rhours,
    minutes: rminutes,
  }
}

export const minutesToTimeStamp = minutes => {
  const rHours = Math.floor(minutes / 60)
  const rMinutes = minutes - rHours * 60

  return {
    hours: rHours,
    minutes: rMinutes,
  }
}

export const addLeadingZeros = num => {
  return `${num}`.padStart(2, '0')
}

export const addLeadingZerosToTime = time => {
  let newTime = ''
  const times = time.split(':')

  times.forEach((num, index) => {
    const intNum = parseInt(num)

    newTime += `${addLeadingZeros(intNum)}`

    if (index < times.length - 1) {
      newTime += ':'
    }
  })

  return newTime
}

export const isToday = someDate => {
  const today = new Date()
  const otherDate = new Date(someDate)

  return otherDate.getDate() == today.getDate() && otherDate.getMonth() == today.getMonth() && otherDate.getFullYear() == today.getFullYear()
}

export const addDays = (date, days) => {
  const dateClass = new Date(date)
  dateClass.setDate(dateClass.getDate() + days)
  return dateClass
}

export const writeDate = (date, seperator = '/', order = 'default') => {
  const dateClass = new Date(date)

  return order === 'date'
    ? `${addLeadingZeros(dateClass.getFullYear())}${seperator}${addLeadingZeros(dateClass.getMonth() + 1)}${seperator}${addLeadingZeros(dateClass.getDate())}`
    : `${addLeadingZeros(dateClass.getDate())}${seperator}${addLeadingZeros(dateClass.getMonth() + 1)}${seperator}${addLeadingZeros(dateClass.getFullYear())}`
}

export const getNumberOfWeek = date => {
  const dateClass = new Date(date)
  const firstDayOfYear = new Date(dateClass.getFullYear(), 0, 1)
  const pastDaysOfYear = (dateClass - firstDayOfYear) / 86400000
  const weeknr = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  return weeknr > 52 ? 1 : weeknr
}

export const getDateOfWeek = (w, y) => {
  const date = new Date(y, 0, 1 + (w - 1) * 7)
  date.setDate(date.getDate() + (1 - date.getDay()))
  return date
}

export const weeksInYear = year => {
  const d = new Date(year, 11, 31)
  const week = getNumberOfWeek(d)
  return week == 1 ? 52 : week
}

export const weeksBetween = (date1, date2) => {
  // The number of milliseconds in one week
  const ONE_WEEK = 1000 * 60 * 60 * 24 * 7
  // Convert both dates to milliseconds
  const date1_ms = date1.getTime()
  const date2_ms = date2.getTime()
  // Calculate the difference in milliseconds
  const difference_ms = Math.abs(date1_ms - date2_ms)
  // Convert back to weeks and return hole weeks
  return Math.floor(difference_ms / ONE_WEEK)
}
